.App {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

a {
  color:#ec9742;
  text-decoration: underline;
  font-weight: 600;
}

.Header {
  display: flex;
  flex: 1;
  padding: 2rem;
  border-bottom: 1px solid #ec9742;
  justify-content: space-between;
  align-items: center;
}

.Footer {
  display: flex;
  flex: 1;
  padding: 2rem;
  border-top: 1px solid #ec9742;
  justify-content: space-between;
  align-items: center;
}

.MuiDialogActions-root {
  visibility: hidden !important;
}

.MuiTabs-root {
  align-items: center !important;
  padding: 0.5rem !important;
}

.MuiTabs-scroller {
  border: #080809 1rem !important;
}

.MuiTabScrollButton-root {
  background-image: linear-gradient(160deg, rgba(255, 255, 255, 0) 0%, rgba(246, 249, 245, 0.498) 30%, rgb(251, 251, 250) 100%);
  border-radius: 25px !important;
  height: 2rem !important;
  width: 2rem !important;
  margin: 0px 1rem 0px 1rem !important;
  transition: 0.5s;
  color: #ec9742 !important;
  text-shadow: 1px 1px 6px #080809 !important;
}

.MuiTabScrollButton-root:hover {
  background-color: #fffffffe !important;
}