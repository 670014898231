body {
  margin: 0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(7, 7, 7);
  text-shadow: 1px 2px 5px #25994ce4 !important;
  overflow-x: hidden;
  min-height: 100vh;
}

@keyframes scrolling {
  0% {
    background-position: left;
  }

  50% {
    background-position: right;
  }

  100% {
    background-position: left;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}